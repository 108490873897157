import React, { useState, useEffect, useRef } from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import { allowScroll } from 'src/libs/scroll';
import Button from 'src/components/Button';
import CartProductCard from 'src/components/Header/CartPopup/CartProductCard';
import CrossButton from 'src/components/buttons/CrossButton';
import formatPrice from 'src/utils/formatPrice';
import GetBasket from 'src/libs/BergCommerceAPI/Basket/GetBasket';
import Link from 'src/components/Link/index';
import ProductListingHeading from 'src/components/Cart/ProductListingHeading';
import PropTypes from 'prop-types';
import SearchCategorySuggestions from 'src/components/Search/SearchCategorySuggestions';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const Wrapper = styled('div')`
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 12px;

    ${above.tablet_s} {
        padding: 32px;
    }

    backdrop-filter: blur(${theme.blur.standard.desktop});
`;

const Popup = styled('div')`
    margin-left: auto;
    position: relative;
    background-color: ${theme.color.white};
    border: 1px solid ${theme.color.black};
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 32px);
    width: 720px;
    max-width: 100%;
    padding: 16px 0 16px 16px;

    ${above.tablet_s} {
        padding: 24px 0 24px 24px;
    }

    //So text doesn't end up behind mobile bottom bar

    ${below.desktop_s} {
        padding-bottom: 40px;
    }
`;

const PopupHead = styled('div')`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    width: 100%;
    padding-bottom: 24px;
`;

const StyledSearchCategorySuggestionsWrapper = styled('div')`
    border-top: 1px solid ${theme.color.black};
    margin-right: 24px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 420px;

    ${above.desktop_s} {
        display: flex;
        align-items: center;
    }

    ${below.desktop_s} {
        display: flex;
        align-items: center;
        height: 360px;
    }
`;

const StyledSearchCategorySuggestions = styled(SearchCategorySuggestions)`
    ${above.desktop_s} {
        width: 100%;
        height: auto;
    }

    ${below.tablet_s} {
        ${theme.spacing.mobile._40('padding-top')};
        margin-top: 50px;
    }
`;

const PopupBody = styled('div')`
    flex: 1;
    overflow-y: auto;
    padding-right: 16px;
    position: relative;

    ${above.tablet_s} {
        padding-right: 24px;
        border: none;
    }
`;

const OuterButtonsWrapper = styled('div')`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    right: 0;
    padding: 16px;

    ${above.tablet_s} {
        padding: 24px;
    }
`;

const CrossButtonWrapper = styled('div')`
    cursor: pointer;
`;

const Cart = styled('div')`
    display: block;
    color: ${theme.color.black};
`;

const CartHeading = styled('div')`
    font-size: 24px;
    line-height: 1;
    position: relative;

    &::after {
        display: inline-block;
        content: attr(data-number);
        font-size: 12px;
        top: 0;
        position: absolute;
        margin-left: 4px;
    }
`;

const CheckoutButton = styled(Button)`
    width: 100%;
    text-align: center;
`;

const CheckoutWrapper = styled('div')`

    ${above.desktop_s} {
        margin-left: auto;
        max-width: 300px;
    };
`;

const TotalsWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    color: ${theme.color.black};
    padding: 24px 0;

    ${below.tablet_s} {
        padding: 20px 0;
    };

    span {
        font-size: 24px;
    }
`;

const PopupBottom = styled('div')`
    margin-right: 16px;

    ${above.desktop_s} {
        border-top: 1px solid ${theme.color.black};
        margin-right: 24px;
    };
`;

const ProductListingHeadingWrapper = styled('div')`
    padding-right: 16px;

    ${above.tablet_s}{
        padding-right: 24px;
    }
`;

const CartPopup = ({ closeCartPopup }) => {
    const [value, setValue] = useState(0); // integer state
    const useForceUpdate = () => {
        return () => setValue(value => value + 1); // update the state to force render
    };

    const [cart, setCart] = useState({});
    const forceUpdate = useForceUpdate();

    const popupRef = useRef();

    useEffect(() => {
        // Create an scoped async function in the hook
        const fetchCartData = async () => {
            const response = await GetBasket();
            setCart(response);
        };
        // Execute the created function directly
        fetchCartData();
    }, [value]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (popupRef.current.contains(e.target)) {
                return null;
            }
            closeCartPopup();
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef, closeCartPopup]);

    let totalItems = 0;
    // This calculates the quantity
    cart?.items && cart.items.forEach((product) => {
        totalItems=totalItems+product.quantity;
    });

    const currency = '€';
    const cartTotal = cart.totals?.subtotal || '';

    const handleGoToCheckout = () => {
        closeCartPopup();
        allowScroll();
    };

    return (
        <Wrapper>
            <Popup ref={popupRef}>
                <PopupHead>
                    <Cart>
                        { cart.items !== undefined && cart.items.length > 0 ?
                            <CartHeading data-number={totalItems}>Cart</CartHeading>
                        :
                        <>
                            <CartHeading>Your cart is empty</CartHeading>
                            <StyledSearchCategorySuggestionsWrapper>
                                <StyledSearchCategorySuggestions />
                            </StyledSearchCategorySuggestionsWrapper>

                        </>
                    }
                    </Cart>
                </PopupHead>
                {cart.items !== undefined && cart.items.length > 0 && (
                    <>
                        <ProductListingHeadingWrapper>
                            <ProductListingHeading />
                        </ProductListingHeadingWrapper>
                        <PopupBody>
                            {Array.isArray(cart.items) &&
                                cart.items.map((product, index) => {
                                    const variation = product.variation_data?.Licence || '';
                                    const price = formatPrice(product.price_raw);

                                    return (
                                        <CartProductCard
                                            key={index}
                                            forceUpdate={forceUpdate}
                                            licenseText={variation}
                                            price={`${currency} ${price}`}
                                            product={product}
                                        />
                                    );
                                })}
                        </PopupBody>
                        <PopupBottom>
                            <CheckoutWrapper>
                                <TotalsWrapper>
                                    <span>Subtotal:</span>
                                    <span>
                                        {currency} {formatPrice(cartTotal)}
                                    </span>
                                </TotalsWrapper>
                                <Link src="/checkout" onClick={handleGoToCheckout}>
                                    <CheckoutButton>Go to checkout</CheckoutButton>
                                </Link>
                            </CheckoutWrapper>
                        </PopupBottom>
                    </>
                )}
                <OuterButtonsWrapper>
                    <CrossButtonWrapper>
                        <CrossButton rotated onClick={closeCartPopup} />
                    </CrossButtonWrapper>
                </OuterButtonsWrapper>
            </Popup>
        </Wrapper>
    );
};

CartPopup.propTypes = {
    closeCartPopup: PropTypes.func,
};

CartPopup.defaultProps = {
    closeCartPopup: null,
};

export default CartPopup;